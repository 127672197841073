.contact-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    text-align: center;
}

.contact-card h1 {
    font-family: "Libre Baskerville", serif;
    color: #0463a6;
}

.contact-card h2 {
    font-family: "Libre Baskerville", serif;
    font-size: 2rem;
    color: #af3021;
    margin: 0;
    padding: 0;
}

.contact-card h3 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: #1a8dc5;
}

.contact-card p {
    color: #af3021;
}
