.book-card {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    background-color: #1a8dc5;
    background-image: url(../../assets/backgrounds/blue_background.png);
    background-size: cover;
}

.book-jacket {
    margin: 15px;
    padding: 10px;
}

.book-jacket img {
    width: 415px;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
}

.book-description {
    margin: 10px;
    padding: 10px;
    max-width: 80%;
    color: #ffffff;
}

.book-title h1 {
    font-family: "Libre Baskerville", serif;
    font-size: 4rem;
    padding: 0;
    margin: 0;
}

.book-title h3 {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 0.05rem;
    padding: 0;
    margin: 0;
    text-align: justify;
}

.book-blurb {
    padding-top: 10px;
}

.book-blurb p {
    font-weight: 400;
    font-size: 1.25rem;
    padding: 0;
    margin: 0;
    text-align: justify;
}

.book-description button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 5px 20px 5px;
    margin: 15px 15px;
    border: none;
    border-radius: 10px;
    background: #af3021;
    color: #ffffff;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.book-description button:hover {
    border: none;
    border-radius: 10px;
    background: #92281c;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.purchase-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.purchase-buttons a {
    text-decoration: none;
    color: #ffffff;
}

@media (max-width: 420px) {
    .book-jacket img {
        width: 100%;
    }

    .book-description button {
        width: 90%;
    }
}

@media (min-width: 421px) {
    .book-description button {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .book-card {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .book-title h1 {
        text-align: center;
    }
    .book-title h3 {
        text-align: center;
    }
}

@media (min-width: 769px) {
    .book-card {
        flex-direction: row;
        justify-content: space-around;
    }

    .book-jacket {
        margin-left: 40px;
    }

    .book-description {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        width: 800px;
    }

    .book-description button {
        width: 35%;
    }
}
