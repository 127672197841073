.press-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #1a8dc5;
    background-image: url(../../assets/backgrounds/blue_background.png);
    background-size: cover;
}

.press-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    text-align: center;
    color: white;
}

.press-card h1 {
    font-family: "Libre Baskerville", serif;
}

.press-card h2 {
    font-family: "Libre Baskerville", serif;
    font-size: 2rem;
}

.press ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.press ul li {
    margin-bottom: 40px;
}

.press ul li h3 {
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.press ul li p {
    font-weight: 400;
    font-size: 1.25rem;
    padding: 0;
    margin: 0;
}

.press ul li p a {
    font-weight: 600;
    font-size: 1.75rem;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: #ffffff;
}
