.side-navbar-navigation {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 0%;
    display: flex;
    flex-direction: column;
    background-color: #1a8dc5;
    z-index: 200;
    transition: all 0.5s ease-out;
}

.side-navbar-navigation.open {
    width: 70%;
    transition: all 0.5s ease-in;
}

.side-navbar-navigation ul li a {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    position: relative;
}

.side-navbar-options ul li a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: ".";
    color: transparent;
    background: #ffffff;
    height: 2px;
}

.side-navbar-options ul li a:hover:after {
    width: 100%;
}

.side-navbar-options ul li a,
.side-navbar-options ul li a:after,
.side-navbar-options ul li a:before {
    transition: all 0.5s;
}

.side-navbar-options ul {
    list-style: none;
    margin: 1rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-navbar-options li {
    padding: 0.5rem;
    margin: 1rem 2rem;
}

.side-navbar-options a {
    color: #ffffff;
    text-decoration: none;
    padding-bottom: 5px;
}

.side-navbar-options a:hover {
    color: #ffffff;
    text-decoration: none;
}

.side-navbar-options a:active {
    color: #ffffff;
    text-decoration: none;
}

@media (min-width: 769px) {
    .side-navbar-navigation {
        display: none;
    }
}

@media (min-width: 426px) {
    .side-navbar-navigation ul li a {
        font-size: 1.75rem;
        font-weight: 500;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        position: relative;
    }
}
