html {
    height: 100%;
    width: 100%;
}

body {
    background-color: #f2d404;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: "Roboto Condensed", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-family: "Libre Baskerville", serif;
    font-size: 4rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
