.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    background-color: #f2d404;
    z-index: 50;
}

.navbar-navigation {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.navbar-spacer {
    flex: 1;
}

.navbar-book-logo {
    display: flex;
    flex-direction: column;
    width: 150px;
    align-items: center;
    justify-content: center;
}

.navbar-book-title a {
    color: #0463a6;
    text-decoration: none;
    font-size: 2rem;
    font-family: "Libre Baskerville", serif;
    flex-direction: column;
    align-self: center;
    width: 100%;
}
.navbar-book-author a {
    color: #af3021;
    text-decoration: none;
    font-size: 1.25rem;
    letter-spacing: 0.05rem;
    font-family: "Roboto Condensed", sans-serif;
    flex-direction: column;
    align-self: center;
    width: 100%;
}

.navbar-options ul li a {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin: 0 10px;
    position: relative;
}

.navbar-options ul li a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: ".";
    color: transparent;
    background: #0463a6;
    height: 2px;
}

.navbar-options ul li a:hover:after {
    width: 100%;
}

.navbar-options ul li a,
.navbar-options ul li a:after,
.navbar-options ul li a:before {
    transition: all 0.5s;
}

.navbar-options ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar-options li {
    padding: 0 0.5rem;
}

.navbar-options a {
    color: #af3021;
    text-decoration: none;
    padding-bottom: 5px;
}

.navbar-options a:hover {
    color: #0463a6;
    text-decoration: none;
}

.navbar-options a:active {
    color: #0463a6;
    text-decoration: none;
}

@media (max-width: 768px) {
    .navbar-options {
        display: none;
    }
}

@media (min-width: 769px) {
    .navbar-hamburger {
        display: none;
    }
}
