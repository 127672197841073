.about-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f2d404;
}

.about-card {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.author-image img {
    width: 400px;
    border-radius: 2px;
}

.author-info {
    margin: 10px;
    padding: 10px;
    max-width: 80%;
    color: #af3021;
}

.author-info h1 {
    font-family: "Libre Baskerville", serif;
    font-size: 4rem;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #0463a6;
}

.author-info p {
    font-weight: 400;
    font-size: 1.25rem;
    padding: 0;
    margin: 0;
    text-align: justify;
}

@media (min-width: 769px) {
    .about-card {
        flex-direction: row;
        justify-content: space-around;
    }

    .author-info {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        width: 800px;
    }
}
